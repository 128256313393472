<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-if="posts.length > 0">
    <div class="add-property-title mb-5"> {{ $t('roommate-offers') }}</div>
    <div class="mb-3" v-for="post in posts" v-if="!isLoading">
      <router-link :to="{name: 'EditRoommateOffer', params: {id: post.id}}">
        <RoommateAd :adtype="false == true ? 'premium' : ''"
                    :title="post.title"
                    :name="post.name"
                    :city="post.city.name"
                    :location="post.district.name"
                    :date="post.date"
                    :price="post.price"
                    :description="post.description"
                    :image="post.images.length > 0 ? post.images[0].src : null"
        />
      </router-link>
    </div>
    <div class="pagination">
      <Pagination :size="'small'"/>
    </div>
  </div>
</template>

