import Pagination from '@/components/Components/Pagination/Pagination';
import RoommateAd from "@/components/Components/RoommateAd/RoommateAd";

export default {
    name: 'AdminViewingRequests',
    components: {
        RoommateAd,
        Pagination,
    },
    data() {
        return {
            posts: [],
            total: null,
            pages: null,
            isLoading: false,
            postsPerPage: 10,
        };
    },
    methods: {},

    async mounted() {
        this.isLoading = true;
        const posts = await this.$store.dispatch('roommate/getUserRoommatePosts', {});
        this.posts = posts.data;
        this.total = posts.total;
        this.pages = Math.ceil(this.total / this.postsPerPage);
        this.isLoading = false;
    },
};
